/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700&display=swap');


.backbtn{
    position: absolute;
    left: 2%;
    top: 1%;
} 

.btn-mob{
    height: 54px;
    margin-top: 15%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #F6F6F6;
    border-radius: 8px;
}

.google-btn{
    margin-top: 20px;
    --background: white;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.54);
    border: solid 1px;
    border-radius: 8px;
    height: 54px;
}

.fb-btn{
    margin-top: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #F6F6F6;
    border-radius: 8px;
    height: 54px;
}
.mob-input{
    height: 54px;
    margin-top: 15%; 
    border: 1px solid #C9C9C9;
    box-sizing: border-box;
    background: #F6F6F6;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 8px;
    background: #F6F6F6;
    ion-input{
        border:0;
    }
}
#login-container {
    width:100%;
    height:100%;
}



ion-content{
    background: var(--app-page-bg);
}

.login-bottom-bg {
    background-color: var(--app-page-bg);
    background-image: url(/assets/icon/login_footer.svg) !important;
    background-repeat: no-repeat !important;
    background-position: left bottom !important;;
    background-size: 100% !important;
}

.hor-center {
    display:flex;
    justify-content: center;
}


#otpcontainer {
    display:flex;
    justify-content: space-around;
    padding-left: 6%;
    padding-right: 8%;
    
    input {
        border: 1px solid #7EB907;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        width: 54px;
        height: 56px;
        padding-left: 20px !important;
        font-size: 16px;
        font-weight: 600;
        margin-left:11px;
        background: transparent;
        outline: none;
    }
}

.alert-popover-width {
    --width: 280px;
}

ion-action-sheet {
    --ion-color-primary: #000;
    .action-sheet-title {
        text-align: left !important;
        margin-left: 10px;
    }

    .action-sheet-button-inner {
        justify-content: flex-start !important;
    }

    .action-sheet-button {
        font-size: 13px !important;
        font-weight: 500 !important;
        color: #767676 !important;
    }
}

.alert-div {
    width: 280px;
    height: 290px;
    border-radius: 12px;
    background: var(--app-alerts-header-bg);
    padding-top: 13px;
}

.alert-content {
    width: 100%;
    height: 277px;
    border-radius: 12px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.page-green-bg {
    background-color: var(--app-header-bg);
    width: 100%;
    height: 100%;
}

.page-white-bg {
    background-color: var(--app-page-bg-after-login);
    width: 100%;
    height: 100%;
    padding: 15px;
    border-top-right-radius: var(--page-top-border-radius);
    border-top-left-radius: var(--page-top-border-radius);
}

.page-title {
    height: 44px;
    width: 100%;
    text-align: center;
    border-top-left-radius: var(--page-top-border-radius);
    border-top-right-radius: var(--page-top-border-radius);
    border-bottom: 1px solid gray;
    font-weight: 500;
    font-size: 16px;
    color: #767676;
    text-transform: uppercase;
    padding-top: 8px;
    background: #FFFFFF;
}

.no-padding {
    padding: 0;
}

ion-toast {
    --ion-color-base: black;
    color: white;
}

.form-field-error-text {
    color: red;
    font-size: 10px;
    margin-top: 5px;
}

ion-label {
    font-size: 13px;
    font-weight: 500;
    color: #767676;
}

.app-logo {
    width: 128px;
    height: 128px;
}

.page-top-border {
    border-top-right-radius: var(--page-top-border-radius);
    border-top-left-radius: var(--page-top-border-radius);
}


.text-xs {
    font-weight: 500;
    font-size: 11px;
}

.text-sm {
    font-weight: 500;
    font-size: 13px;
}

.text-md {
    font-weight: 500;
    font-size: 16px;
}

.text-lg {
    font-weight: 500;
    font-size: 17px;
}


.text-link {
    color: var(--brand-color) !important;
}

.text-light {
    color: #767676;
}

.text-bold {
    font-weight: 600 !important;
}

.pin-container {
    min-width: 295px;
}

.pin-heading {
    color: var(--brand-color) !important;
}

.pin-content {
    margin-top: -15px;
}

.pin-map-icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.pin-addrss-text-container {
    display: flex;
    align-items: center;
}

.pin-addrss-text {
    font-size: 14px;
}

.pin-info-container {
    display: flex;
    margin-top: -5px;
}

.pin-star-image {
    width: 15px;
    height: 15px;
    margin-right: 2px;
    color: var(--brand-color) !important;
}

.pin-info-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.footer-padding {
    padding-bottom: 10px;
}

.footer-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-btn {
    width: 90%;
    height: 44px;
    color: white;
    background-color: var(--brand-color);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}

input[type="checkbox"] {
    accent-color: var(--brand-color);
}
